<template>
  <div class="thing_goods_add">
    <el-card>
      <el-form class="left ml15 mt15 w350" :rules="infoRules" ref="Form" :model="info" label-width="120px">
        <el-tabs v-model="activeName" @tab-click="handleClick">

          <el-tab-pane label="基本信息" name="1" :key="1">
            <el-form-item label="物料封面" prop="goods_img">
              <div class="previewUpload">
                <div v-show="info.goods_img" class="previewItem">
                  <img :src="info.goods_img" alt="">
                  <i class="el-icon-close delIcon" @click="delThumb('img',0)"></i>
                </div>
                <div class="previewItems" @click="openUploadDialog(1,'goodsImg')"><i class="el-icon-plus previewadd"></i></div>
              </div>
            </el-form-item>
            <el-form-item label="物料货号" prop="goods_sn">
              <el-input v-model="info.goods_sn"  placeholder="请输入物料货号"></el-input>
            </el-form-item>
            <el-form-item label="物料名称" prop="goods_name">
              <el-input v-model="info.goods_name"  placeholder="请输入物料名称"></el-input>
            </el-form-item>
            <el-form-item label="物料分类" prop="category_id">
              <treeselect 
                v-model="info.category_id" 
                :options="category_list" 
                :normalizer="normalizer" 
                :show-count="true" 
                placeholder="请选择物料分类" 
              />
            </el-form-item>
            <el-form-item label="物料库存" prop="goods_number" v-if="info.goods_id&&!info.attrtype">
                  <el-input v-model="info.goods_number" :disabled="true" placeholder="请输入物料库存" style="margin-left: 10px;"></el-input>
                  <el-input v-model="goods_number" placeholder="请输入库存" v-if="gn_status" style="width: 150px; margin-left: 10px;"></el-input>

                  <el-button type="primary" plain @click="ruku" v-if="gn_status=='jian'" style="margin-left: 10px;">确定删减</el-button>
                  <el-button type="primary" plain @click="ruku" v-if="gn_status=='jia'" style="margin-left: 10px;">确定入库</el-button>
                  <el-button type="danger" plain @click="gn_status=''" v-if="gn_status" style="margin-left: 10px;">返回</el-button>

                  <el-button type="danger" plain @click="gn_status='jian'" v-if="!gn_status" style="margin-left: 10px;">-</el-button>
                  <el-button type="primary" plain @click="gn_status='jia'" v-if="!gn_status" style="margin-left: 10px;">+</el-button>
            </el-form-item>
            <el-form-item label="物料原价" prop="original_price">
              <el-input v-model="info.original_price"  placeholder="请输入物料原价">
                <template slot="append">元</template>
              </el-input>
            </el-form-item>
            <el-form-item label="物料售价" prop="shop_price">
              <el-input v-model="info.shop_price"  placeholder="请输入物料售价">
                <template slot="append">元</template>
              </el-input>
            </el-form-item>
            <el-form-item label="物料市场价" prop="market_price">
              <el-input v-model="info.market_price"  placeholder="请输入物料市场价">
                <template slot="append">元</template>
              </el-input>
            </el-form-item>
            <el-form-item label="物料单位" prop="unit">
              <el-input v-model="info.unit"  placeholder="请输入物料单位"></el-input>
            </el-form-item>
            <el-form-item label="是否上架" prop="is_on_sale">
              <el-radio-group v-model="info.is_on_sale">
                <el-radio :label="'1'">是</el-radio>
                <el-radio :label="'0'">否</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="排序" prop="sort_order">
              <el-input v-model="info.sort_order"  placeholder="请输入排序" type="number"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="goodsSubmit">提交</el-button>
            </el-form-item>
          </el-tab-pane>

          <el-tab-pane label="商品详情" name="2" :key="2" class="spdesc">
            <el-form-item prop="goods_desc">
              <Editor v-model="info.goods_desc"></Editor>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="goodsSubmit">提交</el-button>
            </el-form-item>
          </el-tab-pane>

          <el-tab-pane label="商品属性" name="3" :key="3">
            <el-form-item label="属性分类" prop="attrtype">
              <el-select v-model="info.attrtype" placeholder="请选择属性分类" @change="getAttrList">
                <el-option
                  v-for="(item,index) in attrtype_list"
                  :key="index"
                  :label="item.attrtype_name"
                  :value="item.attrtype_id">
                </el-option>
              </el-select>
            </el-form-item>

            <el-row v-if="attr_list.length>0">
              <el-col :span="24" v-for="(item,index) in attr_list" :key="index" style="margin-bottom: 20px;">
                <el-row>
                  <el-col :span="24" v-for="(v,k) in item.attr" :key="k" class="m-b-10">
                    <el-row>
                      <el-col :span="3" class="attr-col">
                        <span @click="addAttrItem(index)" class="attr-add" v-if="k==0&&item.attr_type==1">[+]</span>
                        <span @click="deleteAttrItem(index,k)" class="attr-add" v-if="k>0">[-]</span>
                        {{item.attr_name}}
                      </el-col>
                      <el-col :span="6">
                        <el-input v-model="v.attr_value" placeholder="请输入" v-if="!item.attr_input_type" style="width: 100%;"></el-input>
                        <el-select v-model="v.attr_value" placeholder="请选择" v-else style="width: 100%;">
                          <el-option
                            v-for="(vv,kk) in item.values"
                            :key="kk"
                            :label="vv"
                            :value="vv">
                          </el-option>
                        </el-select>
                      </el-col>
                      <el-col :span="3" class="attr-col">
                        排序
                      </el-col>
                      <el-col :span="2">
                        <el-input v-model="v.attr_order" placeholder="请输入排序" style="width: 80px;"></el-input>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>

            <el-form-item>
              <el-button type="primary" @click="goodsSubmit">提交</el-button>
            </el-form-item>
          </el-tab-pane>

          <el-tab-pane label="物料相册" name="4" :key="4" class="spdesc">
            <el-form-item prop="img_list">
              <div class="previewUpload">
                <div v-show="info.img_list.length>0" class="previewItem" v-for="(item,index) in info.img_list" :key="index">
                  <img :src="item.img_path" alt="">
                  <i class="el-icon-close delIcon" @click="delThumb('imgs',index)"></i>
                </div>
                <div class="previewItems" v-if="info.img_list.length==0" @click="openUploadDialog((15-info.img_list.length),'imgs')"><i class="el-icon-plus previewadd"></i></div>
              </div>
              
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="goodsSubmit">提交</el-button>
            </el-form-item>
          </el-tab-pane>

        </el-tabs>
      </el-form>
    </el-card>

    <Upload :uploadVisible.sync="uploadVisible" :pictureType="pictureType" :chooseImgCount="chooseImgCount" @close="closeDelDialog" @receiveRes="receiveRes"></Upload>
  </div>
</template>

<script>
import axios from 'axios'
import Upload from '@/components/Upload/Upload.vue'
import Editor from '@/components/Editor/Editor.vue'
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
export default {
  components:{
    Upload,Treeselect,Editor
  },
  data () {
    const price = (rule, value, callback) => {
      let ret = /^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/;
      if(!value || !ret.test(value)) callback(new Error('请输入正确的金额，最多保留两位小数'))
      callback()
    }
    return {
      info:{
        goods_id:'',
        category_id: null,
        goods_sn:'',
        goods_name:'',
        goods_number:'',
        market_price:'0.00',
        shop_price:'0.00',
        original_price:'0.00',
        goods_brief:'',
        goods_desc:'',
        goods_thumb:'',
        goods_img:'',
        is_on_sale:'1',
        sort_order: 99,
        unit:'',
        attrtype: null,
        img_list: [],
      },
      category_list: [],
      attrtype_list: [],
      attr_list: [],
      activeName:'1',

      goods_number: '',
      gn_status: '',

      //上传图片
      uploadVisible:false,
      pictureType:'',
      chooseImgCount:0,

      //
      infoRules:{
        goods_img:[
          {required: true, message:'请选择封面图片', trigger: 'blur'}
        ],
        category_id:[
          {required: true, message:'请选择分类', trigger: 'blur'}
        ],
        goods_name:[
          {required: true, message:'请输入商品名称', trigger: 'blur'}
        ],
        market_price:[
          {validator: price, trigger: 'blur'}
        ],
        shop_price:[
          {validator: price, trigger: 'blur'}
        ],
        original_price:[
          {validator: price, trigger: 'blur'}
        ],
      },
    }
  },
  created() {
		if(!this.$store.state.userInfo) {
			// 防止未登录
			this.$router.push({
				path: '/login'
			})
		} else {
      this.getCategoryList();
      this.getAttrtypeList();
      if (this.$route.query.goods_id) {
        this.info.goods_id = this.$route.query.goods_id;
        this.getInfo();
      }
		}
	},
  methods: {
    getCategoryList(){
      var url = 'thing/category_list';
      let params = {};
      this.fd_post(url, params).then((res) => {
        // console.log(res);
        if(res.status) {
          this.category_list = res.list;
        } else {
          this.$message.error(res.msg);
        }
      }).catch((err) => {
        this.$message.error('网络错误');
      });
    },
    getAttrtypeList(){
      var url = 'thing/attrtype_list_select';
      let params = {};
      this.fd_post(url, params).then((res) => {
        // console.log(res);
        if(res.status) {
          this.attrtype_list = res.list;
        } else {
          this.$message.error(res.msg);
        }
      }).catch((err) => {
        this.$message.error('网络错误');
      });
    },
    getInfo(){
      var url = 'thing/goods_detail';
      let params = {
        goods_id: this.info.goods_id
      };
      this.fd_post(url, params).then((res) => {
        console.log(res);
        if(res.status) {
          this.info = res.detail;
          this.attr_list = res.attr_list;
        } else {
          this.$message.error(res.msg);
        }
      }).catch((err) => {
        this.$message.error('网络错误');
      });
    },
    normalizer(node) {
      // console.log(node);
      if(node.children && !node.children.length) {
        delete node.children;
      }
      return {
        id: node.category_id,
        label: node.category_name,
        children: node.children,
      };
    },
    back(){
      this.$router.go(-1)
    },
    //上传图片
    openUploadDialog(count,pictureType){
      this.pictureType = pictureType;
      this.chooseImgCount = count;
      this.uploadVisible = true;
    },
    closeDelDialog(){
      this.uploadVisible=false;
    },
    receiveRes(chooseList, pictureType){
      console.log(chooseList);
      if(pictureType==='goodsImg'){
        this.info.goods_img = chooseList[0].img_path;
      } else if(pictureType==='imgs'){
        for (var i = 0; i < chooseList.length; i++) {
          this.info.img_list.push({img_id: '', img_path:chooseList[i].img_path});
        }
        // this.info.img_list = this.info.img_list.concat(chooseList);
      }
      this.uploadVisible=false;
    },
    delThumb(type,index){
      if (type=='img') {
        this.info.goods_img = '';
      } else {
        this.info.img_list.splice(index,1);
      }
    },
    handleClick(tab, event){
      // console.log(tab, event);
    },
    getAttrList(e) {
      console.log(e);
      var url = 'thing/get_attr_list';
      let params = {
        attrtype_id: e
      };
      this.fd_post(url, params).then((res) => {
        console.log(res);
        if(res.status) {
          this.attr_list = res.list;
        } else {
          this.$message.error(res.msg);
        }
      }).catch((err) => {
        this.$message.error('网络错误');
      });
    },
    addAttrItem(index) {
      this.attr_list[index].attr.push({attr_value: '', attr_order: 99});
    },
    deleteAttrItem(index,k) {
      this.attr_list[index].attr.splice(k,1);
    },
    //基本设置
    goodsSubmit(){
      this.$refs.Form.validate(valid=>{
        console.log(this.info);
        console.log(this.attr_list);
        if(!valid) return;
        // return;
        var url = 'thing/goods_doadd';
        var params = this.info;
        params.img_list = JSON.stringify(this.info.img_list);
        params.attr_list = JSON.stringify(this.attr_list);
        if (this.info.attrtype == null) {
          params.attrtype = 0;
        }
        console.log(params);
				this.fd_post(url, params).then((res) => {
          console.log(res);
					if(res.status) {
						this.$message.success('操作成功');
						this.$router.push({path: '/thing_goods'});
					} else {
						this.$message.error(res.msg);
					}
				}).catch((err) => {
					this.$message.error('网络错误');
				});
      })
    },
    ruku() {
      var url = 'thing/ruku';
      if (this.goods_number<=0) {
        this.$message.error("请输入大于0的数字");return;
      }
      var params = {
        goods_id: this.info.goods_id,
        fuhao: this.gn_status,
        goods_number: this.goods_number
      };
      console.log(params);
      this.fd_post(url, params).then((res) => {
        if(res.status) {
          if (this.gn_status=='jian') {
            this.info.goods_number = Number(this.info.goods_number)-Number(this.goods_number);
          } else {
            this.info.goods_number = Number(this.info.goods_number)+Number(this.goods_number);
          }
          this.gn_status = '';
          this.$message.success('操作成功');
        } else {
          this.$message.error(res.msg);
        }
      }).catch((err) => {
        this.$message.error('网络错误');
      });
    },
  },
}
</script>

<style>
  .thing_goods_add .spdesc .el-form-item__content{
    margin-left: 0!important;
  }
  .thing_goods_add .vue-treeselect__placeholder, .vue-treeselect__single-value{
    line-height: 40px;
  }
  .thing_goods_add .attr-col{
    text-align: right; 
    padding-right: 20px;
    line-height: 40px;
  }
  .thing_goods_add .attr-add{
    cursor: pointer;
  }
  .thing_goods_add .vue-treeselect__control{
    width: 350px;
  }
</style>
<style lang="scss" scoped>
.thing_goods_add{
  .left{
    text-align: left;
  }
  .ml15{
    margin-left: 15px;
  }
  .mt15{
    margin-top: 15px;
  }
  .tooltips{
    font-size: 18px;
    margin-left:15px
  }
  .mb15{
    margin-bottom: 15px;
  }
  .center{
    text-align: center;
  }
  .w350{
    .el-input{
      width: 350px;
    }
    .el-textarea{
      width: 650px;
    }
  }
  .previewUpload{
    display: flex;
    flex-wrap: wrap;
    .previewItems{
      width: 178px;
      height: 178px;
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      margin:0 10px 10px 0;
      cursor: pointer;
      position: relative;
      .previewadd {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
      }
      &:hover{
        border-color: #409EFF;
      }
    }
    .previewItem{
      width: 178px;
      height: 178px;
      border-radius: 6px;
      margin:0 10px 10px 0;
      cursor: pointer;
      position: relative;
      .delIcon{
        display: none;
      }
      &:hover{
        .delIcon{
          display: block;
          position: absolute;
          top: 5px;
          right: 5px;
          color: white;
          font-size: 30px;
        }
      }
      
      img{
        width: 100%;
        height: 100%;
      }
      
    }
  }
  ::v-deep .ql-editor{
    min-height: 500px;
  }
  ::v-deep .upload-demo{
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    width: 350px;
    border: 1px solid #DCDFE6;
    border-radius: 5px;
    .el-button{
      margin-right: 5px;
    }
    .el-upload-list{
      flex: 1;
    }
  }
  ::v-deep .el-upload-list__item {
    margin: 0;
    transition: none !important ;
    &:hover{
      background-color: #fff;
    }
    i{
      display: none !important;
    }
  }
  .el-input__inner[type="number"]::-webkit-outer-spin-button,
  ::v-deep .el-input__inner[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
    margin: 0;
  }
}
</style>